import React from 'react';
import PropTypes from 'prop-types';
import { Image, Row, Col, Container } from 'react-bootstrap';
import TitleWithBg from './TitleWithBg';
import AboutusFourCards from '../images/about-us-four-cards-2.png';
import Advantage1 from '../images/advantage-1.svg';
import Advantage2 from '../images/advantage-2.svg';
import Advantage3 from '../images/advantage-3.svg';
import Advantage4 from '../images/advantage-4.svg';

function AdvantageCard({ title, image = 1, description }) {
  return (
    <Col md={3} sm={6} xs={12} className="advantage-col">
      <div className="advantage-card">
        <Image src={image} alt={title} />
        <p className="title">{title}</p>
        <p className="description">{description}</p>
      </div>
    </Col>
  );
}

AdvantageCard.propTypes = {
  image: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.string,
};

export default () => {
  return (
    <section className="aboutus-we-3">
      <Container>
        <h2 className="title-with-before">
          <TitleWithBg title="我们是谁" />
        </h2>
        <div className="about-us-description">
          Convertlab荟聚是服务B2B企业以线索管理为核心的营销自动化SaaS产品，包含
          <TitleWithBg title="全" />
          <TitleWithBg title="渠" />
          <TitleWithBg title="道" />
          <TitleWithBg title="获" />
          <TitleWithBg title="客" />
          <TitleWithBg title="管" />
          <TitleWithBg title="理" />
          、
          <TitleWithBg title="私" />
          <TitleWithBg title="域" />
          <TitleWithBg title="流" />
          <TitleWithBg title="量" />
          <TitleWithBg title="运" />
          <TitleWithBg title="营" />
          、
          <TitleWithBg title="自" />
          <TitleWithBg title="动" />
          <TitleWithBg title="化" />
          <TitleWithBg title="线" />
          <TitleWithBg title="索" />
          <TitleWithBg title="培" />
          <TitleWithBg title="育" />
          、
          <TitleWithBg title="市" />
          <TitleWithBg title="场" />
          <TitleWithBg title="销" />
          <TitleWithBg title="售" />
          <TitleWithBg title="协" />
          <TitleWithBg title="同" />
          四个核心部分。通过360度线索画像，不同获客渠道的链路及成本监测，营销自动化和线索分配自动化，帮助B2B企业实现精准获客增长，提高留资转化，优化营销投放的ROI和市场与销售的有机协同。
        </div>
        <div className="about-us-description">
          Convertlab成立于2015年，是中国领先的一体化营销云企业，并于2020年完成腾讯产业生态投资和光速中国领投的超一亿B轮融资，为企业营销提供数字化、自动化、智能化基础设施以及相关的专业服务。
        </div>
      </Container>
      <Container className="about-us-image-container">
        <Image src={AboutusFourCards} className="about-us-four-cards" />
      </Container>
      <Container className="our-advantages">
        <h2 className="title-with-before">
          <TitleWithBg title="选择我们的优势所在" />
        </h2>
        <Row>
          <AdvantageCard
            image={Advantage1}
            title="国内首批营销云产品，市场的效仿者"
            description="产品成熟度高，功能深度和广度大大领先国内同类产品，许多设计理念、功能与能力都被业内追随效仿。"
          />
          <AdvantageCard
            image={Advantage2}
            title="丰富的生态合作伙伴集成经验"
            description="对于对接各类系统进行数据采集加工和输出，积累了丰富的经验，并对快速革新的营销市场保持迅速响应与迭代。"
          />
          <AdvantageCard
            image={Advantage3}
            title="以客户体验为中心驱动产品设计"
            description="从营销自动化平台（DM Hub）起家，各产品从底层设计上即强调实时性、灵活性、开放性以及业务性。"
          />
          <AdvantageCard
            image={Advantage4}
            title="从策略咨询到代运营的全链服务体系"
            description="具备从咨询、系统建设到营销应用的All-in-one服务经验，打造了以企业增长为导向的客户成功体系，积累了大量B2B项目经验。"
          />
        </Row>
      </Container>
    </section>
  );
};
