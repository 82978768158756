import React, { useState } from 'react';
import { Container, Carousel, Row } from 'react-bootstrap';
import Develop from '../images/aboutus-development.svg';
import TitleWithBg from './TitleWithBg';

const HISTORY = [
  {
    time: '2016',
    dic: 'CRM产品年度创新奖',
    top: '50%',
    left: '-6%',
  },
  {
    time: '2017',
    dic:
      '第八届金鼠标年度最佳数字营销平台&第八届虎啸年度最具潜力新创公司奖&在上海举办首届中国Mar Tech(MTCC 2017)峰会&第四届中国创新传播大奖年度最佳作品',
    top: '26%',
    left: '10%',
  },
  {
    time: '2018',
    dic:
      '收录于“中国营销技术云图”并入选年度新锐营销技术企业TOP20&与百度一起代表中国营销技术实力收录至“2018全球营销技术生态图”&在北京举办第二届中国Mar Tech(MTCC 2018)峰会&荣获年度商业创新企业&中国企业服务商业创新CEO&财经峰会上荣获“2018新经济年度人物”及“年度影响力企业”奖项',
    top: '36%',
    left: '26%',
  },
  {
    time: '2019',
    dic:
      '与阿里巴巴、百度一同收录于“2019全球营销技术生态图”,营销自动化板块唯一连续两年入选的中国企业&成为浦发银行第二批科技合作共同体伙伴&举办第三届中国Mar Tech(MTCC 2019)峰会',
    top: '32%',
    left: '42.3%',
  },
  {
    time: '2020',
    dic:
      '荟聚营销云正式上线, 专注于解决B2B营销场景&AIHub启动并投入应用&入选腾讯SaaS加速器全球40强&荣获中国营销技术研究院Mar Tech50&荣获2020 Topdigtal Awards SCRM&被收录于全球营销技术生态图谱',
    top: '-19%',
    left: '58%',
  },
  {
    time: '2021',
    dic: '荟聚斩获第四届金匠奖年度营销管理计数服务金匠奖&荣获Digital π 首席数字官峰会2020-21年度TOP50产品&未完待续…',
    top: '-8%',
    left: '74.5%',
  },
];

function PCShow() {
  return (
    <Container className="pc-show">
      <div>
        <h2 className="title-with-before">
          <TitleWithBg title="发展历程" />
        </h2>
        <div className="image-wrapper">
          <img src={Develop} alt="Develop" />
        </div>
        <ul>
          {HISTORY.map((item, index) => {
            const arr = item.dic.split('&');
            return (
              <React.Fragment key={item.time}>
                <li key={`development-history-li${item.time}`}>
                  <h6>{item.time}</h6>
                  {arr.map((item1) => {
                    return <p key={`development-history-p${item1}`}>{item1}</p>;
                  })}
                </li>
                {index !== HISTORY.length - 1 && (
                  <span
                    key={`${item.time}development-history-border`}
                    className="development-history-li-border"
                  />
                )}
              </React.Fragment>
            );
          })}
        </ul>
      </div>
    </Container>
  );
}

function MobShow() {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  return (
    <Container className="mobile-show">
      <h2 className="title-with-before">
        <TitleWithBg title="发展历程" />
        <div className="development-history-mobile-wrapper">
          <img style={{ width: '100%', height: '100%' }} src={Develop} alt="" />
          {HISTORY.map((item, timeIndex) => (
            <span
              key={`development-history-time${item.time}`}
              className="development-history-time"
              style={{
                display: timeIndex === index ? 'block' : 'none',
                top: item.top || 0,
                left: item.left || 0,
              }}
            >
              <em />
              <br />
              {item.time}
            </span>
          ))}
        </div>
      </h2>
      <Carousel
        indicators={false}
        activeIndex={index}
        onSelect={handleSelect}
        interval={3000}
        id="development-history-carousel"
      >
        {HISTORY.map((item) => {
          const content = item.dic.split('&');
          return (
            <Carousel.Item key={`development${item.time}`}>
              {content.map((item1) => (
                <p key={`development-hisrory-content${item1}`}>{item1}</p>
              ))}
            </Carousel.Item>
          );
        })}
      </Carousel>
      <Row className="development-mobile-carousel-slipe justify-content-center">
        {HISTORY.map((item, e) => (
          <span
            key={`development-mobile-carousel-slipe${item.time}`}
            style={{ fontSize: 0, background: index === e ? '#000' : 'rgba(0, 0, 0, 0.2)' }}
            onClick={() => {
              setIndex(e);
            }}
            onKeyDown={() => {}}
            role="button"
            tabIndex={-1}
          >
            .
          </span>
        ))}
      </Row>
    </Container>
  );
}

export default () => {
  return (
    <section id="development-history" className="development-history clearfix">
      <PCShow />
      <MobShow />
    </section>
  );
};
