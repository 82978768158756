import React from 'react';
import Banner2 from '../components/Banner2';
import AboutusWe3 from '../components/AboutusWe3';
import BannerImage from '../images/aboutus-banner.png';
import Layout from '../components/Layout';
import BannerRightImage from '../images/banner-right.svg';
import DevelopmentHistory from '../components/DevelopmentHistory';
import HomeLogos from '../components/HomeLogos';

const BannerRight = () => {
  return (
    <div className="banner-right-container">
      <img src={BannerRightImage} alt="about-us" />
    </div>
  );
};

export default () => (
  <Layout current="aboutus">
    <Banner2
      title="一体化B2B营销云"
      title2="实现『超级增长』"
      backgroundImage={BannerImage}
      rightRender={<BannerRight />}
    />
    <AboutusWe3 />
    <DevelopmentHistory />
    <div className="about-us-logos">
      <HomeLogos />
    </div>
  </Layout>
);
